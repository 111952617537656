import React from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'
import { LandingPageContainer } from '../useLandingPage'
import settings from '../../../assets/images/settings.png'

const ColumnChart = ({ chartData, field, showModal, setIsModalOpen }) => {
  const intl = useIntl()
  const { contextThresholds } = LandingPageContainer.useContainer()

  const data = chartData[0].data
  const minValue = data.reduce((min, p) => (p.y < min ? p.y : min), data[0].y)
  const maxValue = data.reduce((max, p) => (p.y > max ? p.y : max), data[0].y)

  const options: ApexOptions = {
    fill: {
      colors: ['#5b9ad5'],
    },

    chart: {
      foreColor: '#59595B',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      width: '100%',
      height: 30,
      type: 'bar',
      stacked: false,
      animations: {
        enabled: true,
      },

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [
            {
              icon: `<img src=${settings} width="15" />`,
              index: 0,
              title: 'Settings',
              click: function () {
                setIsModalOpen(true)
                showModal()
              },
            },
          ],
        },
      },
    },

    tooltip: {
      enabled: true,
      y: {
        formatter: value => {
          return intl.formatNumber(value)
        },
        title: {
          formatter: () => {
            if (field === 'functionExecutions') {
              return 'Function Executions'
            }
            if (field === 'totalInvocations') {
              return 'Transactions'
            }
          },
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        colors: {
          ranges: [
            {
              from: minValue,
              to:
                contextThresholds[field] !== ''
                  ? contextThresholds[field]
                  : maxValue,
              color: '#5b9ad5',
            },
            {
              from: contextThresholds[field],
              to: maxValue,
              color: contextThresholds[field] !== '' ? '#cc6540' : '#5b9ad5',
            },
          ],
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      tickAmount: 6,
      labels: {
        rotate: 0,
        datetimeUTC: false,
        style: {
          fontSize: '11px',
        },
        formatter: value => {
          const date = new Date(value).toISOString()
          const datetime = DateTime.fromISO(date)
          const humanTimestamp = datetime.toFormat('dd MMM')
          return humanTimestamp
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y:
            contextThresholds[field] !== ''
              ? contextThresholds[field]
              : -100000000000,
          borderColor: '#8d342d',
          strokeDashArray: 3,
        },
      ],
    },
  }

  return <Chart options={options} series={chartData} type="bar" height={200} />
}

export default ColumnChart
