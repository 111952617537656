import { useState } from 'react'
import { createContainer } from 'unstated-next'

export const useLandingPage = () => {
  const [thresholds, setThresholds] = useState({
    totalInvocations: '',
    functionExecutions: '',
  })

  const setContextThreshold = (name: string, value: number) => {
    setThresholds(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  return {
    contextThresholds: thresholds,
    setContextThreshold,
  }
}

export const LandingPageContainer = createContainer(useLandingPage)
