import React, { useEffect } from 'react'
import { useMetricsData } from '../../Settings/Usage/useUsage'
import { BillingServices } from '../../Settings/Usage/types'
import { useLogin } from '../../App/LoginContext'
import Cards from './Cards'

const ColumnCharts = () => {
  const { token } = useLogin()
  const functionExecutions = useMetricsData(BillingServices.Functions)
  const totalInvocations = useMetricsData(BillingServices.TotalInvocations)

  const data = [
    {
      state: functionExecutions,
      field: 'functionExecutions',
    },
    {
      state: totalInvocations,
      field: 'totalInvocations',
    },
  ]

  useEffect(() => {
    if (token) {
      functionExecutions.run()
      totalInvocations.run()
    }
  }, [token])

  return (
    <>
      {data.map(el => {
        return <Cards state={el.state} field={el.field} key={el.field} />
      })}
    </>
  )
}

export default ColumnCharts
