import MonacoEditor, { EditorWillMount } from 'react-monaco-editor'
import { noop, isString } from 'lodash-es'
import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { colors, clearSky } from '@waylay/react-components'
import extendDeclarativeBinding from '../../../Common/Editor/languages/declarative-binding'
import { IPropertyInput } from '../PropertyInput'
import { HStack } from '~/components/Common/Stack'
import OpenInEditor from '../OpenInEditor'
import { useSafeEditorContainer } from '../EditorContext'

export const EditorFieldWrapper = styled.div`
  border: solid 1px #e8e8e8;
  border-color: rgb(232, 232, 232);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.333333rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.85rem;
  flex: 1;

  & :focus-within {
    border-color: ${colors.withWeight(clearSky, 300)} !important;
  }

  & * {
    font-weight: 400 !important;
    caret-color: black;
  }

  & .monaco-editor input {
    color: #59595b !important;
  }

  & .monaco-editor .view-line span {
    color: #59595b !important;
  }
`

interface IEditorFieldWrapper extends IPropertyInput {
  language?: string
}

interface IEditorField extends IEditorFieldWrapper {
  editorWillMount?: EditorWillMount
}

const EditorFieldContainer = ({
  property,
  value: _value,
  language = 'declarative-binding',
  setFieldValue,
  isDeclarativelyBound,
}: IEditorFieldWrapper) => {
  const value = isString(_value) ? _value : _value ? JSON.stringify(_value) : '' // editor expects a string
  const valueWithoutLineBreaks = value.replace(/(\r\n|\n|\r)/gm, '  ') // single line EditorField does not allow linebreaks

  if (language === 'declarative-binding') {
    return (
      <DeclarativeBindingEditorField
        property={property}
        value={value}
        language={language}
        setFieldValue={setFieldValue}
        isDeclarativelyBound={isDeclarativelyBound}
      />
    )
  }

  return (
    <EditorField
      property={property}
      value={valueWithoutLineBreaks}
      language={language}
      setFieldValue={setFieldValue}
      isDeclarativelyBound={isDeclarativelyBound}
    />
  )
}

const EditorField = ({
  property,
  value,
  language,
  setFieldValue,
  editorWillMount = noop,
  isDeclarativelyBound,
}: IEditorField) => {
  return (
    <HStack>
      <EditorFieldWrapper>
        <MonacoEditor
          width="100%"
          height="1.45rem"
          language={language}
          value={value}
          onChange={value => setFieldValue(property.name, value)}
          theme={null}
          options={{
            minimap: { enabled: false },
            glyphMargin: false,
            folding: false,
            lineNumbers: 'off',
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            scrollbar: { vertical: 'hidden', horizontal: 'hidden' },
            guides: { highlightActiveIndentation: false },
            renderLineHighlight: 'none',
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            fixedOverflowWidgets: true,
            fontFamily: 'Fira Sans',
            fontSize: 14,
            colorDecorators: false,
          }}
          editorWillMount={editorWillMount}
        />
      </EditorFieldWrapper>
      <OpenInEditor
        property={property}
        value={value}
        setFieldValue={setFieldValue}
        isDeclarativelyBound={isDeclarativelyBound}
      />
    </HStack>
  )
}

const DeclarativeBindingEditorField = (props: IEditorFieldWrapper) => {
  const context = useSafeEditorContainer()
  const declarativeBindingSchema = context?.declarativeBindingSchema

  const editorWillMount = useCallback(
    editor => {
      if (declarativeBindingSchema) {
        extendDeclarativeBinding(editor, declarativeBindingSchema)
      }
    },
    [declarativeBindingSchema],
  )

  return <EditorField editorWillMount={editorWillMount} {...props} />
}

export default EditorFieldContainer
