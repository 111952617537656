import React, { ReactNode, useEffect } from 'react'
import { IfFulfilled, IfPending, IfRejected, useAsync } from 'react-async'
import styled from '@emotion/styled'
import { get } from 'lodash-es'
import {
  carbon,
  Segment,
  StreamlineIcon,
  Loader,
} from '@waylay/react-components'
import {
  PluginsModules,
  ProgramingLanguages,
} from '@streamlinehq/streamline-bold/lib/programing-apps-websites'
import { Analytics } from '@streamlinehq/streamline-bold/lib/business-products'
import { TextOptions } from '@streamlinehq/streamline-bold/lib/interface-essential'
import { formatCompactNumber } from './Common'
import { NavLink } from 'react-router-dom'
import client from '../../lib/client'
import { BillingServices } from '../Settings/Usage/types'
import { useMetricsData } from '../Settings/Usage/useUsage'
import { REFRESH_INTERVAL } from '~/lib/Constants'
import { useLogin } from '../App/LoginContext'

interface CardItem {
  icon: string | ReactNode
  name: string
  path: string
  data: any
  technicalField: string
  backgroundColor?: string
}

const CardsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1.25em;
`

const CardWrapper = styled(Segment)`
  display: flex;
  align-items: center;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  padding: 1em 1.5em;

  :hover {
    transform: scale(1.02);
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  color: ${carbon};
`
const Number = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  padding-bottom: 0.2em;
`

const Label = styled.div`
  font-size: 0.9em;
`

const CardIcon = styled.span<{ backgroundColor: string }>`
  min-width: 55px;
  height: 55px;
  font-size: 32px;
  color: ${carbon};
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 25px;
  background-color: ${props => props.backgroundColor};
`

const fetchTemplates = async () => {
  const response = await client.templates.list(
    { hits: 1, startIndex: 0 },
    { wantsFullResponse: true },
  )
  return response
}

const fetchWebscripts = async () => {
  const response = await client.webscripts.list(
    { limit: 0 },
    { wantsFullResponse: true },
  )
  return response
}

const fetchResources = async () => {
  const response = await client.resources.search(
    { limit: 1, skip: 0 },
    { wantsFullResponse: true },
  )
  return response
}

const Cards = () => {
  const { token } = useLogin()

  const ingestedDataPoints = useMetricsData(BillingServices.TimeSeries)

  const fetchTemplatesCounter = useAsync({
    deferFn: async () => await fetchTemplates(),
  })

  const fetchWebscriptsCounter = useAsync({
    deferFn: async () => await fetchWebscripts(),
  })

  const fetchResourcesCounter = useAsync({
    deferFn: async () => await fetchResources(),
  })

  useEffect(() => {
    if (token) {
      fetchTemplatesCounter.run()
      fetchWebscriptsCounter.run()
      fetchResourcesCounter.run()
      ingestedDataPoints.run()
    }
    const intervalId = setInterval(() => {
      fetchTemplatesCounter.run()
      fetchWebscriptsCounter.run()
      fetchResourcesCounter.run()
      ingestedDataPoints.run()
    }, REFRESH_INTERVAL)
    return () => {
      clearInterval(intervalId)
    }
  }, [token])

  const cardsData: CardItem[] = [
    {
      icon: PluginsModules.ModuleFour,
      name: 'Resources',
      data: fetchResourcesCounter,
      path: '/resources',
      technicalField: 'data.total',
      backgroundColor: '#a5d2ff',
    },

    {
      icon: TextOptions.TaskText,
      name: 'Rule Templates',
      data: fetchTemplatesCounter,
      path: '/templates',
      technicalField: 'headers.x-count',
      backgroundColor: '#91d6ae',
    },
    {
      icon: ProgramingLanguages.ProgrammingLanguageHttp,
      name: 'Webscripts',
      data: fetchWebscriptsCounter,
      path: '/webscripts',
      technicalField: 'headers.x-count',
      backgroundColor: '#f3de93',
    },
    {
      icon: Analytics.AnalyticsMountain,
      name: 'Ingested Datapoints for current month',
      data: ingestedDataPoints,
      path: '/analytics/queries',
      technicalField: 'ingestedDatapoints.value.results.aggregated',
      backgroundColor: '#bcbcbd',
    },
  ]

  return (
    <CardsRow>
      {cardsData.map(item => (
        <Card key={item.name} item={item} />
      ))}
    </CardsRow>
  )
}

const Card = ({ item }: { item: CardItem }) => {
  return (
    <>
      <CardWrapper
        as={NavLink}
        to={item.path}
        data-testid={`card-item-${item.name}`}
      >
        <CardIcon
          backgroundColor={item.backgroundColor}
          data-testid={`card-icon-${item.name}`}
        >
          <StreamlineIcon iconData={item.icon} />
        </CardIcon>
        <Details data-testid={`card-detail-${item.name}`}>
          <IfPending state={item.data}>
            <Details
              style={{
                paddingBottom: '0.5em',
                height: '26px',
              }}
            >
              <Loader />
            </Details>
            <Label data-testid={`card-name-${item.name}`}>{item.name}</Label>
          </IfPending>
          <IfRejected state={item.data}>
            <Label style={{}}>
              We could not retrieve the number of {item.name}
            </Label>
          </IfRejected>

          <IfFulfilled state={item.data} data-testid={`card-data-${item.name}`}>
            {data => (
              <>
                <Number data-testid={`card-number-${item.name}`}>
                  {formatCompactNumber(get(data, item.technicalField, 0))}
                </Number>
                <Label data-testid={`card-name-${item.name}`}>
                  {item.name}
                </Label>
              </>
            )}
          </IfFulfilled>
        </Details>
      </CardWrapper>
    </>
  )
}

export default Cards
