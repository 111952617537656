import React, { useState } from 'react'
import {
  Segment,
  Loader,
  carbon,
  Button,
  StreamlineIcon,
} from '@waylay/react-components'
import styled from '@emotion/styled'
import { IfFulfilled, IfPending, IfRejected } from 'react-async'
import { get } from 'lodash-es'
import Container from './Container'
import { formatCompactNumber } from '../Common'
import { DateTime } from 'luxon'
import { Alerts } from '@streamlinehq/streamline-bold/lib/interface-essential'
import { useFlags } from '../../../lib/flags'
import { COLUMN_CHARTS_TITLES, REFRESH_INTERVAL } from '~/lib/Constants'
import useInterval from '~/hooks/useInterval'

enum DISPLAY_TYPE {
  THIRTY_DAYS = 30,
  SEVEN_DAYS = 7,
}

const Details = styled.div`
  color: ${carbon};
  display: flex;
  align-items: center;
`
const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const Number = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  padding-bottom: 0.2em;
  padding-left: 0.7em;
`
const Label = styled.div`
  padding-left: 0.5em;
  font-size: 0.9em;
  width: 100%;
`

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`

const Cards = ({ state, field }) => {
  const flags = useFlags()
  const [displayType, setDisplayType] = useState(DISPLAY_TYPE.SEVEN_DAYS)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const chartData = data => {
    const lastThirtyDays = get(data, `${field}.value.results.daily`, [])
    return displayType === DISPLAY_TYPE.SEVEN_DAYS
      ? lastThirtyDays.slice(-7)
      : lastThirtyDays
  }

  useInterval(() => {
    if (!isModalOpen) {
      state.run()
    }
  }, REFRESH_INTERVAL)

  return (
    <Segment padding="2em 1em" data-testid={`card-${field}`}>
      {!flags.edgeMode ? (
        <>
          <Details>
            <IfPending state={state}>
              <div data-testid="loading-number">
                <Loader size={25} />
              </div>
            </IfPending>

            <IfFulfilled state={state}>
              {data => (
                <Number data-testid="function-executions-number">
                  {formatCompactNumber(
                    get(data, `${field}.value.results.aggregated`, 0),
                  )}
                </Number>
              )}
            </IfFulfilled>
            <Label data-testid="chart-label">
              {COLUMN_CHARTS_TITLES[field]}
            </Label>
            <Button
              outline
              size="tiny"
              onClick={() => {
                setDisplayType(
                  displayType === DISPLAY_TYPE.SEVEN_DAYS
                    ? DISPLAY_TYPE.THIRTY_DAYS
                    : DISPLAY_TYPE.SEVEN_DAYS,
                )
              }}
              data-testid="chart-button"
            >
              Show last{' '}
              {displayType === DISPLAY_TYPE.SEVEN_DAYS
                ? DISPLAY_TYPE.THIRTY_DAYS
                : DISPLAY_TYPE.SEVEN_DAYS}{' '}
              days
            </Button>
          </Details>
          <IfPending state={state}>
            <LoaderWrapper data-testid="loading-table">
              <Loader size={80} />
            </LoaderWrapper>
          </IfPending>

          <IfFulfilled state={state}>
            {data => {
              const newData = chartData(data)
              const values = newData.map(serieElement => {
                const modifiedTime = DateTime.fromISO(serieElement.time)
                  .minus({ seconds: 1 })
                  .toUTC()
                  .toLocaleString()
                return { x: modifiedTime, y: serieElement.value }
              })
              return (
                <Container
                  chartData={[{ data: values }]}
                  field={field}
                  setIsModalOpen={setIsModalOpen}
                />
              )
            }}
          </IfFulfilled>
          <IfRejected state={state}>
            <ErrorMessageWrapper data-testid="error">
              <StreamlineIcon
                iconData={Alerts.AlertTriangle}
                width={40}
                height={40}
              />
              <Label style={{ padding: '1em' }} data-testid="error-text">
                Something went wrong
              </Label>
            </ErrorMessageWrapper>
          </IfRejected>
        </>
      ) : (
        <>
          <ErrorMessageWrapper data-testid="edge-error">
            <StreamlineIcon
              iconData={Alerts.AlertTriangle}
              width={40}
              height={40}
            />
            <Label style={{ padding: '1em' }} data-testid="edge-error-text">
              This feature is unavailable.
            </Label>
          </ErrorMessageWrapper>
        </>
      )}
    </Segment>
  )
}

export default Cards
