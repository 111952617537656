export enum BillingServices {
  Functions = 'Functions',
  TimeSeries = 'Time Series',
  Storage = 'Storage',
  Alarms = 'Alarms',
  Vault = 'Secrets',
  Broker = 'Messages',
  Tasks = 'Tasks',
  Assets = 'Assets',
  MachineLearning = 'Machine Learning',
  Mqtt = 'MQTT',
  Webscripts = 'Webscripts',
  TotalInvocations = 'Total Invocations',
}

enum BillingSeries {
  alarmsOccurred = 'alarmsOccurred',
  vaultKeys = 'vaultKeys',
  storageTotalBytes = 'storageTotalBytes',
  storageTotalBytesReceived = 'storageTotalBytesReceived',
  storageTotalBytesSent = 'storageTotalBytesSent',
  functionComputeTime = 'functionComputeTime',
  functionExecutions = 'functionExecutions',
  nativeFunctionExecutions = 'nativeFunctionExecutions',
  ingestedDatapoints = 'ingestedDatapoints',
  timeseriesReadScan = 'timeseriesReadScan',
  messagesIngested = 'messagesIngested',
  messagesQueried = 'messagesQueried',
  messagesStorageSize = 'messagesStorageSize',
  taskTransitions = 'taskTransitions',
  resources = 'resources',
  resourceTypes = 'resourceTypes',
  resourceConstraints = 'resourceConstraints',
  resourceStorageSize = 'resourceStorageSize',
  byomlComputeTime = 'byomlComputeTime',
  converterInvocations = 'converterInvocations',
  taskLogsStorage = 'taskLogsStorage',
  webscriptExecutions = 'webscriptExecutions',
  taskInvocations = 'invocationCount',
  templateRunInvocations = 'templateRuns',
  totalInvocations = 'totalInvocations',
}

export const metricsRequestMap = {
  [BillingServices.Alarms]: [BillingSeries.alarmsOccurred],
  [BillingServices.Vault]: [BillingSeries.vaultKeys],
  [BillingServices.Storage]: [
    BillingSeries.storageTotalBytes,
    BillingSeries.storageTotalBytesReceived,
    BillingSeries.storageTotalBytesSent,
  ],
  [BillingServices.Functions]: [
    BillingSeries.functionComputeTime,
    BillingSeries.functionExecutions,
    BillingSeries.nativeFunctionExecutions,
  ],
  [BillingServices.TimeSeries]: [
    BillingSeries.ingestedDatapoints,
    BillingSeries.timeseriesReadScan,
  ],
  [BillingServices.Broker]: [
    BillingSeries.messagesIngested,
    BillingSeries.messagesQueried,
    BillingSeries.messagesStorageSize,
  ],
  [BillingServices.Tasks]: [
    BillingSeries.taskTransitions,
    BillingSeries.taskLogsStorage,
    BillingSeries.taskInvocations,
    BillingSeries.templateRunInvocations,
  ],
  [BillingServices.Assets]: [
    BillingSeries.resources,
    BillingSeries.resourceTypes,
    BillingSeries.resourceConstraints,
    BillingSeries.resourceStorageSize,
  ],
  [BillingServices.MachineLearning]: [BillingSeries.byomlComputeTime],
  [BillingServices.Mqtt]: [BillingSeries.converterInvocations],
  [BillingServices.Webscripts]: [BillingSeries.webscriptExecutions],
  [BillingServices.TotalInvocations]: [BillingSeries.totalInvocations],
}

interface IMetricsResponse {
  status: Status
}

enum Status {
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

interface Metric {
  value: {
    results: {
      daily: DailyRow[]
      aggregated: number
    }
    lastChecked: Date
    from: Date
    until: Date
  }
}

interface DailyRow {
  time: Date
  value: number
}

export interface IAlarmsMetricsResponse extends IMetricsResponse {
  alarmsOccurred: Metric
}

export interface IVaultMetricsResponse extends IMetricsResponse {
  vaultKeys: Metric
}

export interface IStorageMetricsResponse extends IMetricsResponse {
  storageTotalBytes: Metric
  storageTotalBytesReceived: Metric
  storageTotalBytesSent: Metric
}

export interface IFunctionsMetricsResponse extends IMetricsResponse {
  functionComputeTime: Metric
  functionExecutions: Metric
  nativeFunctionExecutions: Metric
}

export interface ITimeSeriesMetricsResponse extends IMetricsResponse {
  ingestedDatapoints: Metric
  timeseriesReadScan: Metric
}

export interface IBrokerMetricsResponse extends IMetricsResponse {
  messagesIngested: Metric
  messagesQueried: Metric
  messagesStorageSize: Metric
}

export interface ITasksMetricsResponse extends IMetricsResponse {
  taskTransitions: Metric
  invocationCount: Metric
  taskLogsStorage: Metric
  templateRuns: Metric
}

export interface IAssetsMetricsResponse extends IMetricsResponse {
  resources: Metric
  resourceTypes: Metric
  resourceConstraints: Metric
  resourceStorageSize: Metric
}

export interface IMachineLearningMetricsResponse extends IMetricsResponse {
  byomlComputeTime: Metric
}

export interface IMqttMetricsResponse extends IMetricsResponse {
  converterInvocations: Metric
}

export interface IWebscriptstMetricsResponse extends IMetricsResponse {
  webscriptExecutions: Metric
}
