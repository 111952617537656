import styled from '@emotion/styled'
import EditNameForm from './../Common/EditNameForm'
import { IdentifierWithEllipsis, NameWithEllipsis, Title } from './Overview'
import {
  Icon,
  Segment,
  colors,
  carbon,
  CopyToClipboard,
  clearSky,
  Tooltip,
  concrete,
} from '@waylay/react-components'
import { MetaItem } from './../Common/Overview'
import { Link } from 'react-router-dom'
import { MouseEvent, ReactNode } from 'react'
import { css } from '@emotion/core'

export const DetailsGrid = styled(Segment)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`
const GridContainer = styled.div`
  display: flex;
  width: 100%;
`
export const EllipsisWrapper = styled.span`
  color: ${carbon};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
`
export const DetailContainer = styled.div`
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;

  > a,
  > button {
    margin-right: 0.5em;
    margin-top: 0.5em;
    height: 30px;
  }
`
export const RowFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: hidden;
`
export const HoverIconWrapper = styled.div`
  margin-right: 5px;
  max-width: 100%;

  &:hover {
    ${RowFlexBox} {
      visibility: visible;
    }
  }
`
export const BoxWrapper = styled.div`
  display: flex;
  max-width: 100%;
`
export const MetaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`

const EditTarget = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.25rem;
`
export const ItemRow = styled.div`
  display: flex;
  padding: 0.825em;

  max-width: 100%;

  &:hover {
    background: ${colors.withWeight(concrete, 100)};
  }

  & + & {
    border-top: solid 1px ${colors.withWeight(concrete, 500)};
  }
`
export const CheckBoxContainer = styled.div`
  display: flex;
  padding-right: 10px;
`

export const ItemContainer = styled.div`
  width: calc(100% - 25px);
`

const Centered = css`
  display: flex;
  align-items: center;
  padding: 7px 0;
`

const handleButtonClick = (event: React.MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
}

const TooltipOrName = props =>
  props.tooltip ? (
    <Tooltip
      placement="bottom"
      content={
        <span style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {props.value}
        </span>
      }
    >
      <NameWithEllipsis
        style={{
          marginRight: '0',
          color: props?.isClickable ? clearSky : 'inherit',
          fontSize: props?.fontSize ? '1.5em' : 'inherit',
        }}
        data-testid={props.testId}
      >
        {props.value}
      </NameWithEllipsis>
    </Tooltip>
  ) : (
    <NameWithEllipsis
      style={{
        marginRight: '0',
        color: props?.isClickable ? clearSky : 'inherit',
        fontSize: props?.fontSize ? '1.5em' : 'inherit',
      }}
      data-testid={props.testId}
    >
      {props.value}
    </NameWithEllipsis>
  )

export const DetailName = props => {
  return (
    <Title data-testid="title" to={props?.title}>
      <HoverIconWrapper
        data-testid="hover-icon-wrapper"
        css={props?.centered && Centered}
      >
        {props.editName ? (
          <EditNameForm name={props.value} onSubmit={props.onSubmit} />
        ) : (
          <BoxWrapper>
            <TooltipOrName {...props} />

            <RowFlexBox onClick={handleButtonClick}>
              {props?.isEditable && !props.editName && (
                <EditTarget
                  onClick={() => props.setEditName(true)}
                  data-testid="edit-name"
                >
                  <Icon
                    color={colors.withWeight(carbon, 200)}
                    name="create"
                    data-testid={props?.iconTestId}
                  />
                </EditTarget>
              )}
              <CopyToClipboard
                value={props.value}
                trigger={ref => (
                  <div
                    data-testid="copy-to-clipboard-button"
                    style={{ cursor: 'pointer' }}
                    ref={ref}
                  >
                    <Icon
                      color={colors.withWeight(carbon, 200)}
                      name="file_copy"
                    />
                  </div>
                )}
              />
            </RowFlexBox>
          </BoxWrapper>
        )}
      </HoverIconWrapper>
    </Title>
  )
}

const TooltipOrIdentifier = (id, testId, tooltip) =>
  tooltip ? (
    <Tooltip
      placement="bottom"
      content={
        <span style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {id}
        </span>
      }
    >
      <IdentifierWithEllipsis data-testid={testId}>{id}</IdentifierWithEllipsis>
    </Tooltip>
  ) : (
    <IdentifierWithEllipsis data-testid={testId}>{id}</IdentifierWithEllipsis>
  )

export const DetailId = ({
  id,
  testId,
  tooltip,
}: {
  id: string
  testId?: string
  tooltip?: boolean
}) => {
  return (
    <HoverIconWrapper data-testid="hover-icon-wrapper">
      <BoxWrapper>
        {TooltipOrIdentifier(id, testId, tooltip)}

        <RowFlexBox onClick={handleButtonClick}>
          {id && (
            <CopyToClipboard
              value={id}
              trigger={ref => (
                <div
                  data-testid="copy-to-clipboard-button"
                  style={{ cursor: 'pointer' }}
                  ref={ref}
                >
                  <Icon
                    color={colors.withWeight(carbon, 200)}
                    name="file_copy"
                  />
                </div>
              )}
            />
          )}
        </RowFlexBox>
      </BoxWrapper>
    </HoverIconWrapper>
  )
}

export const DetailMeta = ({
  linkTo,
  text,
  onClick,
  linkTestId,
  testId,
  children,
  hasNoIcon,
}: {
  linkTo?: string
  text: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  linkTestId?: string
  children?: ReactNode
  testId?: string
  hasNoIcon?: boolean
}) => {
  return (
    <HoverIconWrapper data-testid="hover-icon-wrapper">
      <div style={{ display: 'flex' }}>
        <MetaItem
          data-testid={testId}
          onClick={onClick}
          style={{
            maxWidth: '200px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {children}
          {linkTo ? (
            <Link to={linkTo} data-testid={linkTestId}>
              {text}
            </Link>
          ) : (
            <EllipsisWrapper>{text}</EllipsisWrapper>
          )}

          {!hasNoIcon && (
            <RowFlexBox onClick={handleButtonClick}>
              <CopyToClipboard
                value={text}
                trigger={ref => (
                  <div
                    data-testid="copy-to-clipboard-button"
                    style={{ cursor: 'pointer', fontSize: '12px' }}
                    ref={ref}
                  >
                    <Icon
                      color={colors.withWeight(carbon, 200)}
                      name="file_copy"
                    />
                  </div>
                )}
              />
            </RowFlexBox>
          )}
        </MetaItem>
      </div>
    </HoverIconWrapper>
  )
}

export const ListRow = ({
  name,
  id,
  testId,
  nameTestId,
  identifierTestId,
  titleTestId,
  nameIconTestId,
  identifierIconTestId,
  isClickable,
  title,
  hasIcon,
  onTitleClick,
  onIconClick,
}: {
  name?: string
  id?: string
  testId?: string
  nameTestId?: string
  identifierTestId?: string
  titleTestId?: string
  isClickable?: boolean
  nameIconTestId?: string
  identifierIconTestId?: string
  title?: string
  hasIcon?: boolean
  onTitleClick?: (event: MouseEvent<HTMLDivElement>) => void
  onIconClick?: (event: MouseEvent<HTMLDivElement>) => void
}) => {
  return (
    <GridContainer data-testid={testId}>
      <Title to={title} data-testid={titleTestId} onClick={onTitleClick}>
        <Tooltip
          placement="bottom"
          content={
            <span
              style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {name}
            </span>
          }
        >
          <NameWithEllipsis
            style={{
              minWidth: '20px',
              maxWidth: '100%',
              marginRight: '0',
              color: isClickable ? clearSky : 'inherit',
            }}
            data-testid={nameTestId}
          >
            {name}
          </NameWithEllipsis>
        </Tooltip>
        {hasIcon && (
          <HoverIconWrapper data-testid="id-hover-icon-wrapper">
            <RowFlexBox onClick={handleButtonClick}>
              <CopyToClipboard
                value={name}
                trigger={ref => (
                  <div
                    data-testid="name-copy-to-clipboard-button"
                    style={{ cursor: 'pointer' }}
                    ref={ref}
                  >
                    <Icon
                      onClick={onIconClick}
                      data-testid={nameIconTestId}
                      color={colors.withWeight(carbon, 200)}
                      name="file_copy"
                    />
                  </div>
                )}
              />
            </RowFlexBox>
          </HoverIconWrapper>
        )}

        <div
          style={{
            maxWidth: 'calc(100% - 20px)',
            display: 'flex',
          }}
        >
          {id && (
            <HoverIconWrapper data-testid="id-hover-icon-wrapper" style={{}}>
              <BoxWrapper>
                <Tooltip
                  placement="bottom"
                  content={
                    <span
                      style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {id}
                    </span>
                  }
                >
                  <IdentifierWithEllipsis
                    data-testid={identifierTestId}
                    style={{ marginLeft: '7px' }}
                  >
                    {id}
                  </IdentifierWithEllipsis>
                </Tooltip>
                <RowFlexBox onClick={handleButtonClick}>
                  <CopyToClipboard
                    value={id}
                    trigger={ref => (
                      <div
                        data-testid={identifierIconTestId}
                        style={{ cursor: 'pointer' }}
                        ref={ref}
                      >
                        <Icon
                          color={colors.withWeight(carbon, 200)}
                          name="file_copy"
                        />
                      </div>
                    )}
                  />
                </RowFlexBox>
              </BoxWrapper>
            </HoverIconWrapper>
          )}
        </div>
      </Title>
    </GridContainer>
  )
}
