import createFlags from 'flag'
import { get } from 'lodash-es'

export const hasEnv = (key: string) => Boolean(getEnv(key, ''))
export const getEnv = (key: string, defaultValue = ''): string =>
  get(window, ['env', key], defaultValue)

const usesGateway = hasEnv('REACT_APP_WAYLAY_API_GATEWAY_URL')

function hasService(srvcKey: string): boolean {
  if (usesGateway) return true
  const serviceUrl = getEnv(`REACT_APP_WAYLAY_${srvcKey}_URL`)
  if (serviceUrl) return true
  return false
}

function hasFeature(key: string, srvcKey = ''): boolean {
  const enabled = getEnv(`REACT_APP_WAYLAY_${key}_ENABLE`, null)
  if (enabled) return Boolean(enabled === 'true')
  // if not explicitely (dis/en)abled, fall back to endpoint config
  return hasService(srvcKey || key)
}

type FlagValue = boolean | { [key: string]: boolean }
type FeatureFlags = Record<string, FlagValue>

const flags = {
  alarms: hasFeature('ALARMS'),
  byoml: hasFeature('BYOML'),
  nlp: hasFeature('NLP'),
  storage: hasFeature('STORAGE'),
  plugRegistry: hasFeature('REGISTRY'),
  webscripts: hasFeature('WEBSCRIPTS'),
  usage: hasFeature('USAGE'),
  queries: hasFeature('QUERIES'),
  explore: hasFeature('EXPLORE', 'QUERIES'),
  etl: hasFeature('ETL') && hasService('STORAGE'),
  docsUrl: hasEnv('REACT_APP_WAYLAY_DOCS_URL'),
  applications: hasEnv('REACT_APP_WAYLAY_APPLICATIONS_KEY'),
  disableUserManagement: getEnv('REACT_APP_DELEGATED_LOGIN') === 'true',
  whitelabeling: getEnv('REACT_APP_ENABLE_WHITELABELING') === 'true',
  edgeMode: getEnv('REACT_APP_ENABLE_EDGE_MODE') === 'true',
  converter: getEnv('REACT_APP_ENABLE_CONVERTER') === 'true',
  usesGateway,
}

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<FeatureFlags>()
export { FlagsProvider, Flag, useFlag, useFlags }

export default flags
