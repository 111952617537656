import React, { useCallback } from 'react'
import {
  Icon,
  Form,
  Segment,
  Message,
  colors,
  concrete,
  clearSky,
  Button,
} from '@waylay/react-components'
import styled from '@emotion/styled'

import { TaskType, ITag, FetchedTags } from '~/lib/types'
import CronWidget from '../Tasks/Detail/FormModal/CronWidget'

interface ITagsFieldSet {
  tags: ITag[] | FetchedTags
  setTags: Function
}

const HugeIcon = styled(Icon)`
  font-size: 64px;
`

const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 1.5rem 0;
`

const SelectionCard = styled(Segment)`
  width: 120px;
  height: 120px;

  text-align: center;

  cursor: pointer;
  transition: all 150ms;

  user-select: none;

  &:hover {
    background: ${colors.withWeight(concrete, 100)};
  }

  ${props =>
    props.selected &&
    `
    border-color: ${clearSky};
    background: ${colors.withWeight(clearSky, 100)} !important;
  `}
`

const Container = styled('div')`
  margin: 1em 0;

  button {
    margin-right: 0;
  }
`
const ButtonBar = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const Group = styled(Form.Input.Group)`
  margin-bottom: 0.5em;
`

interface ITaskTypeSelector {
  setTaskType: Function
  taskType: TaskType
}

export function TaskTypeSelector({ setTaskType, taskType }: ITaskTypeSelector) {
  function isSelected(type: TaskType) {
    return taskType === type
  }

  return (
    <>
      <SelectionWrapper>
        <SelectionCard
          onClick={() => setTaskType(TaskType.Periodic)}
          selected={isSelected(TaskType.Periodic)}
        >
          Periodic <HugeIcon name="timer" />
        </SelectionCard>
        <SelectionCard
          onClick={() => setTaskType(TaskType.Reactive)}
          selected={isSelected(TaskType.Reactive)}
        >
          Reactive <HugeIcon name="settings_ethernet" />
        </SelectionCard>
        <SelectionCard
          onClick={() => setTaskType(TaskType.Scheduled)}
          selected={isSelected(TaskType.Scheduled)}
        >
          Scheduled <HugeIcon name="calendar_today" />
        </SelectionCard>
        <SelectionCard
          onClick={() => setTaskType(TaskType.OneTime)}
          selected={isSelected(TaskType.OneTime)}
        >
          One Time <HugeIcon name="looks_one" />
        </SelectionCard>
      </SelectionWrapper>

      {isSelected(TaskType.Periodic) && (
        <Message title="Periodic" basic>
          A periodic task will be invoked on a set interval.
        </Message>
      )}
      {isSelected(TaskType.Reactive) && (
        <Message title="Reactive" basic>
          A reactive task will trigger when the resource attached to the task
          receives data. Reactive tasks do not have a task tick.
        </Message>
      )}
      {isSelected(TaskType.Scheduled) && (
        <Message title="Scheduled" basic>
          Allows you to start a task using a&nbsp;
          <a
            href="https://tools.ietf.org/html/rfc5545"
            style={{ color: clearSky, textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
          >
            recurrence rule&nbsp;
          </a>
          or&nbsp;
          <a
            href="https://docs.oracle.com/cd/E12058_01/doc/doc.1014/e12030/cron_expressions.htm"
            style={{ color: clearSky, textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
          >
            cron expression
          </a>
          .
        </Message>
      )}
      {isSelected(TaskType.OneTime) && (
        <Message title="One Time" basic>
          Invokes a task only once.
        </Message>
      )}
    </>
  )
}

interface ITaskTypeConfiguration {
  taskType: TaskType
  schedule: string
  scheduleChange: Function
  frequency: number // expects frequency in milliseconds !
  frequencyChange: Function
  style?: Object
  padding?: number
}

export function TaskTypeConfiguration({
  taskType,
  schedule,
  scheduleChange,
  frequency,
  frequencyChange,
  style = {},
  padding = undefined,
}: ITaskTypeConfiguration) {
  function isSelected(type) {
    return taskType === type
  }

  if (!isSelected(TaskType.Periodic) && !isSelected(TaskType.Scheduled))
    return null

  return (
    <Segment.Group style={style} padding={padding}>
      <Segment.Header>Configuration</Segment.Header>
      <Segment>
        {isSelected(TaskType.Periodic) && (
          <>
            <label htmlFor="frequency">Frequency</label>
            <Form>
              <Form.Input.Group>
                <Form.Input
                  name="frequency"
                  type="number"
                  value={Math.floor(frequency / 1000)}
                  onChange={e =>
                    frequencyChange(parseInt(e.target.value, 10) * 1000)
                  }
                />
                <Form.Input.Adornment right>Seconds</Form.Input.Adornment>
              </Form.Input.Group>
            </Form>
          </>
        )}

        {isSelected(TaskType.Scheduled) && (
          <>
            <label htmlFor="schedule">Schedule</label>
            <Form.Input.Group fluid>
              <Form.Input
                name="schedule"
                value={schedule}
                onChange={e => scheduleChange(e.target.value)}
              />
              <CronWidget
                initialValue={schedule}
                onSave={value => scheduleChange(value)}
              />
            </Form.Input.Group>
          </>
        )}
      </Segment>
    </Segment.Group>
  )
}

export function TaskTags({ tags: _tags = {}, setTags }: ITagsFieldSet) {
  const tags = isFetchedTags(_tags)
    ? Object.entries(_tags).map(([key, value]) => ({ key, value }))
    : [..._tags] // converts to ITag[] if needed

  if (isFetchedTags(_tags)) {
    setTags(tags) // initializes the fetched tags
  }

  const add = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const newEmptyTag = { key: '', value: '' }
      setTags([...tags, newEmptyTag])
    },
    [setTags, tags],
  )

  const remove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
      e.preventDefault()

      const newTags = [...tags]
      newTags.splice(index, 1)
      setTags(newTags)
    },
    [setTags, tags],
  )

  return (
    <Container>
      <label>Tags</label>
      {tags.map((tag, i) => (
        <Group fluid key={i}>
          <Form.Input
            placeholder="Enter tag key"
            style={{ marginRight: '0.25rem', borderRadius: '0.3333rem' }}
            onChange={e => {
              tags[i].key = e.target.value
              setTags(tags)
            }}
            value={tag.key}
          />
          <Form.Input
            placeholder="Enter tag value"
            style={{
              borderTopLeftRadius: '0.3333rem',
              borderBottomLeftRadius: '0.3333rem',
            }}
            onChange={e => {
              tags[i].value = e.target.value
              setTags(tags)
            }}
            value={tag.value}
          />
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => remove(e, i)}
            tabIndex={-1}
          >
            <Icon name="remove" />
          </Button>
        </Group>
      ))}
      <ButtonBar>
        <Button onClick={add}>
          <Icon name="add" /> Add tag
        </Button>
      </ButtonBar>
    </Container>
  )
}

const isFetchedTags = (tags: ITag[] | FetchedTags): tags is FetchedTags => {
  return !Array.isArray(tags)
}
