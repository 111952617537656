import Waylay from '@waylay/client'
import { ErrorFormat } from '@waylay/client/dist/util/axiosutil'
import { UsageClient } from './usageClient'
// we have to parse the value as JSON because that's how it's stored by the
// use-persisted-state package
const token = window.localStorage.getItem('token')
const refreshToken = window.localStorage.getItem('refresh_token')

const {
  REACT_APP_WAYLAY_API_GATEWAY_URL,
  REACT_APP_WAYLAY_API_GATEWAY_ENFORCED,
} = window.env

const enforceGateway = REACT_APP_WAYLAY_API_GATEWAY_ENFORCED
  ? REACT_APP_WAYLAY_API_GATEWAY_ENFORCED !== 'false'
  : !!REACT_APP_WAYLAY_API_GATEWAY_URL
const waylayOptions = {
  token,
  refreshToken,
  enforceGateway,
  gateway: REACT_APP_WAYLAY_API_GATEWAY_URL,
  enforceConfigured: false,
  errorMessageFormat: ErrorFormat.Normal,
  auth: { onTokenRefresh },
  billing: {
    timeout: 20000,
  },
  etl: {
    timeout: 10000,
  },
  pubsub: {
    timeout: 60000,
  },
}
if (!enforceGateway) {
  // only for development overrides
  const {
    REACT_APP_WAYLAY_API_URL,
    REACT_APP_WAYLAY_ALARMS_URL,
    REACT_APP_WAYLAY_DATA_URL,
    REACT_APP_WAYLAY_VAULT_URL,
    REACT_APP_WAYLAY_WEBSCRIPTS_URL,
    REACT_APP_WAYLAY_AUTH_URL,
    REACT_APP_WAYLAY_CONVERTER_URL,
    REACT_APP_WAYLAY_QUERIES_URL,
    REACT_APP_WAYLAY_BYOML_URL,
    REACT_APP_WAYLAY_ETL_URL,
    REACT_APP_WAYLAY_REGISTRY_URL,
    REACT_APP_WAYLAY_RESOURCES_URL,
    REACT_APP_WAYLAY_USAGE_URL,
    REACT_APP_WAYLAY_NLP_URL,
    REACT_APP_WAYLAY_PUBSUB_URL,
    REACT_APP_WAYLAY_STORAGE_URL,
    REACT_APP_WAYLAY_HOARD_URL,
  } = window.env

  const urlOverrides = {
    auth: REACT_APP_WAYLAY_AUTH_URL,
    data: REACT_APP_WAYLAY_DATA_URL,
    alarms: REACT_APP_WAYLAY_ALARMS_URL,
    webscripts: REACT_APP_WAYLAY_WEBSCRIPTS_URL,
    vault: REACT_APP_WAYLAY_VAULT_URL,
    converter: REACT_APP_WAYLAY_CONVERTER_URL,
    queries: REACT_APP_WAYLAY_QUERIES_URL,
    byoml: REACT_APP_WAYLAY_BYOML_URL,
    registry: REACT_APP_WAYLAY_REGISTRY_URL,
    resources: REACT_APP_WAYLAY_RESOURCES_URL,
    types: REACT_APP_WAYLAY_RESOURCES_URL,
    constraints: REACT_APP_WAYLAY_RESOURCES_URL,
    billing: REACT_APP_WAYLAY_USAGE_URL,
    etl: REACT_APP_WAYLAY_ETL_URL,
    storage: REACT_APP_WAYLAY_STORAGE_URL,
    hoard: REACT_APP_WAYLAY_HOARD_URL,
    pubsub: REACT_APP_WAYLAY_PUBSUB_URL,
    nlp: REACT_APP_WAYLAY_NLP_URL,
    globalSettings: REACT_APP_WAYLAY_API_URL,
  }
  Object.entries(urlOverrides).forEach(([key, url]) => {
    if (url) {
      if (waylayOptions[key]) waylayOptions[key].baseUrl = url
      else waylayOptions[key] = { baseUrl: url }
    }
  })
}

const client = new Waylay(waylayOptions)

function onTokenRefresh(err, tokens) {
  if (err) {
    console.error(err)
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refresh_token')
    location.reload() // this should log out the user when refreshing has failed
    return
  }

  const { token, refresh_token: refreshToken } = tokens
  window.localStorage.setItem('token', token)

  if (refreshToken) {
    window.localStorage.setItem('refresh_token', refreshToken)
  }
  // force refresh of the UI
  location.reload()
}

/* USAGE */
export const usage = new UsageClient(client)

/* PUBSUB */
let _pubsubWs

// lazy fetch of pub-sub, partially depends on availability of token/domain
// (only an issue when not using gateway in a multi-tenant environment)
// TODO: make this (and other configuration code) depend on correct token initialization.
export function getPubsubWs({ raise } = { raise: true }) {
  if (!_pubsubWs) {
    const { REACT_APP_WAYLAY_PUBSUB_DOMAIN: pubsubDomain } = window.env
    _pubsubWs = client.pubsub.ws({ domain: pubsubDomain, raise })
  }

  return _pubsubWs
}
// try to initialize pubsub, will happen later if fails
getPubsubWs({ raise: false })

export default client
