import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import ky from 'ky'
import DiscoverComponent from './DiscoverComponent'

export interface ILearnItem {
  title: string
  description: string
  image: string
  link: string
}

export const fetchNewsItems = async (): Promise<ILearnItem[]> =>
  await ky
    .get(
      window.env.REACT_APP_WAYLAY_DISCOVER_URL ||
        'https://static.waylay.io/console/landing/landing-learn-discover.json',
    )
    .json()

export const DiscoverDataComponent = () => {
  const [discoverDataState, setDiscoverDataState] = useState<ILearnItem[]>([])

  const fetchState = (fetcher: () => Promise<ILearnItem[]>) => {
    return useAsync({
      deferFn: async () => {
        return await Promise.resolve(fetcher())
      },
      onResolve(data) {
        setDiscoverDataState(data)
      },
    })
  }
  const fetchDiscoverState = fetchState(fetchNewsItems)

  useEffect(() => {
    fetchDiscoverState.run()
  }, [])

  return <DiscoverComponent discoverData={discoverDataState} />
}

export default DiscoverDataComponent
