import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import {
  StreamlineIcon,
  colors,
  carbon,
  clearSky,
} from '@waylay/react-components'
import { Astronomy } from '@streamlinehq/streamline-bold/lib/science'
import { css } from '@emotion/core'

import { EmptyCollection } from '../ResourcesList/NoResults'

export const Section = ({
  title,
  children,
}: {
  title: string
  children: any
}) => (
  <div>
    {title && <SectionTitle>{title}</SectionTitle>}
    {children}
  </div>
)

export const SectionTitle = styled.h2`
  margin: 0;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  color: ${carbon};
`

export const ClickableWrapper = styled.a`
  text-decoration: none;
  color: inherit;
`

export const ClickableItem = props => {
  const elem = getClickItem(props)
  const clickable = props.to || props.href || props.onClick

  const comp = elem`
    text-decoration: none;
    color: ${clearSky};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${clickable ? 'pointer' : 'inherit'};
    white-space: nowrap;
  `

  const onClick = e => {
    e.stopPropagation()
  }

  return React.createElement(comp, { onClick, ...props })
}

const getClickItem = props => {
  // External link
  if (props.href) return styled.a

  // Internal link
  if (props.to) return styled(Link)

  return styled.span
}

export const Title = styled.h3`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
`

export const Description = styled.p`
  margin: 0.5em 0;
  height: 4.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${carbon};
`

const placeholderStyles = css`
  height: auto;

  header {
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.withWeight(carbon, 500)};
    margin-top: 0.5rem;
  }

  p {
    font-size: 0.9rem;
    color: ${colors.withWeight(carbon, 400)};
  }
`

export const Placeholder = ({
  title,
  icon,
  description,
}: {
  title: string
  icon?: any
  description?: string
}) => (
  <EmptyCollection
    css={placeholderStyles}
    title={title}
    description={description}
    icon={
      <StreamlineIcon
        height={24}
        width={24}
        iconData={icon ?? Astronomy.AstronomyTelescopeStars}
      />
    }
  />
)

export function formatCompactNumber(number: number): string {
  if (number < 1000) {
    return number.toString()
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'
  }
}
