import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors, carbon, Segment } from '@waylay/react-components'
import {
  get,
  omit,
  startsWith,
  reduce,
  toString,
  isObject,
  isNumber,
} from 'lodash-es'
import { useAsync } from 'react-async'

import client from '../../lib/client'

// These list are sorted in the order defined below.
const PROPERTIES_LAYOUT = {
  tickTrigger: 'Tick trigger',
  dataTrigger: 'Data trigger',
}

const CONFIGURATIONS_LAYOUT = {
  cost: 'Sequence',
  pollingPeriod: 'Polling period',
  evictionTime: 'Eviction time',
  timeout: 'Timeout',
  timeoutState: 'Timeout state',
  resource: 'Bound resource',
  policy: 'Invocation policy',
  loopDef: 'Loop Definition',
}

const GridSegment = styled(Segment)`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: max-content auto;
`

const EmptyProperties = styled.div`
  color: ${colors.withWeight(carbon, 300)};
  text-align: center;
  line-height: 0;
  padding: 0.5rem 0;
`

const textOverflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const fetchResource = ({ id }) => {
  if (!id) {
    return Promise.resolve({})
  }

  return client.resources.get(id)
}

const formatContent = content =>
  isObject(content) ? JSON.stringify(content) : toString(content)

const NodeInformation = ({
  node,
  resource: resourceId,
  variables,
}: {
  node: any
  resource?: string
  variables?: any
}) => {
  if (!node.configuration) {
    // gates and transition blocks don't have a configuration
    return null
  }

  // Add dataTrigger and tickTrigger to regular properties
  const { dataTrigger, tickTrigger, ...configuration } = omit(
    get(node, 'configuration', {}),
    'properties',
  )
  const properties = {
    ...get(node, 'configuration.properties', {}),
    dataTrigger,
    tickTrigger,
  }

  // Enhance properties with resolved meta values
  const { isLoading, data: resource } = useAsync(fetchResource, {
    id: resourceId,
    watch: resourceId,
  })

  const [enhancedConfiguration, setEnhancedConfiguration] = useState({})
  const [enhancedProperties, setEnhancedProperties] = useState({})

  useEffect(() => {
    const enhancedProps = reduce(
      configuration,
      (result, value, key) => {
        result[key] = { value }

        if (startsWith(value, 'META.$.')) {
          const variableName = value.replace('META.$.', '')
          const resourceRef = get(resource, [variableName, '$ref'], '').replace(
            '/resources/',
            '',
          )
          if (resourceRef !== '') result[key] = { value, resolved: resourceRef }
        }

        if (startsWith(value, '$')) {
          const variableName = value.replace('$', '')
          const resourceRef = get(variables, [variableName], '')
          if (resourceRef !== '') result[key] = { value, resolved: resourceRef }
        }

        return result
      },
      {},
    )

    setEnhancedConfiguration(enhancedProps)
  }, [node, resource])

  useEffect(() => {
    const enhancedProps = reduce(
      properties,
      (acc, value, key) => {
        acc[key] = { value }

        if (startsWith(value, '$')) {
          const variableName = value
            .replace(/\${variables./g, '')
            .replace(/\${task./g, '')
            .replace(/}/g, '')
          const resourceRef =
            variableName.toLowerCase() === 'resource'
              ? resourceId
              : get(variables, [variableName], '')
          if (resourceRef !== '') acc[key] = { value, resolved: resourceRef }
        }

        return acc
      },
      {},
    )

    setEnhancedProperties(enhancedProps)
  }, [node])

  if (isLoading) return null

  return (
    <Segment.Group>
      <Segment.Header>Node Configuration</Segment.Header>
      <GridSegment>
        {properties.length === 0 ? (
          <EmptyProperties>No properties</EmptyProperties>
        ) : (
          Object.entries(enhancedProperties).map(([key, value]) => (
            <Property key={key} name={key} value={value} />
          ))
        )}
      </GridSegment>
      <Segment.Header
        css={css`
          border-top: none;
        `}
      >
        Advanced configuration
      </Segment.Header>
      <GridSegment>
        {Object.entries(enhancedConfiguration).map(([key, value]) => (
          <Configuration key={key} name={key} value={value} />
        ))}
      </GridSegment>
    </Segment.Group>
  )
}

const Property = ({ name, value: propValue }) => {
  const { value, resolved } = propValue
  const niceName = PROPERTIES_LAYOUT[name] || name
  const text = parsePropertyValue(value)

  return <InformationItem label={niceName} value={text} resolved={resolved} />
}

const Configuration = ({ name, value: propValue }) => {
  const { value, resolved } = propValue
  const niceName = CONFIGURATIONS_LAYOUT[name] || name
  const text = parsePropertyValue(value)

  return <InformationItem label={niceName} value={text} resolved={resolved} />
}

const InformationItem = ({ label, value, resolved }) => (
  <>
    <strong>{label}</strong>
    <span css={textOverflow} title={formatContent(value)}>
      {formatContent(value)}
    </span>
    {resolved !== undefined && (
      <>
        <span />
        <span css={textOverflow} title={formatContent(resolved)}>
          ↳ {formatContent(resolved)}
        </span>
      </>
    )}
  </>
)

const parsePropertyValue = value => {
  if (value === true) return 'true'
  else if (value === false) return 'false'
  else if (isObject(value)) return JSON.stringify(value)
  else if (isNumber(value)) return value
  else return toString(value) || '-'
}

export default NodeInformation
