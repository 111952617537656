import { useAsync } from 'react-async'
import { usage } from '~/lib/client'
import { BillingServices, metricsRequestMap } from './types'
import { DateTime } from 'luxon'

// fetch data from the beginning of the month
const fetchUsage = async ({ series }) => await usage.metrics(series)

function useUsage(service: BillingServices) {
  return useAsync(fetchUsage, {
    series: metricsRequestMap[service],
    watch: service,
  })
}

// fetch data for the past thirty days
const lastThirtyDays = DateTime.now()
  .minus({ days: 30 })
  .startOf('day')
  .toUTC()
  .set({ hour: 0, minute: 0, second: 1, millisecond: 0 })

const fetchMetricsData = async ({ series, from }) =>
  await usage.metrics(series, from)

function useMetricsData(service: BillingServices) {
  return useAsync({
    deferFn: async () =>
      await fetchMetricsData({
        series: metricsRequestMap[service],
        from: lastThirtyDays.toMillis(),
      }),
    watch: service,
  })
}

export { useUsage, useMetricsData }
