import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { concrete, carbon } from '@waylay/react-components'

export enum FooterTypes {
  Link = 'Link',
  Version = 'Version',
}

export interface FooterItems {
  Name: string
  Value: string
  Type?: FooterTypes
}

const footerData: FooterItems[] = [
  {
    Name: 'Version',
    Value: window.env.REACT_APP_VERSION,
    Type: FooterTypes.Version,
  },
  {
    Name: 'Documentation',
    Value: window.env.REACT_APP_WAYLAY_DOCS_URL,
  },
  {
    Name: 'Videos',
    Value: 'https://www.youtube.com/channel/UCUTl2DVKgX1I-ubWqIJ_kew',
  },
  {
    Name: 'Feedback',
    Value:
      'https://waylay.atlassian.net/servicedesk/customer/portal/1/group/1/create/21',
  },
  {
    Name: 'Support',
    Value: 'https://waylay.atlassian.net/servicedesk/customer/portal/1',
  },
]

const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  border-top: solid 1px ${concrete};
  width: 100%;
  height: 50px;
  background-color: #fafafa;
  color: ${carbon};
  z-index: 2;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const FooterWrapperCenter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: auto;
`
const commonStyles = css`
  color: ${carbon};
  margin-right: 5px;
`

const FooterVersion = styled.div`
  ${commonStyles}
`

const FooterLink = styled.a`
  ${commonStyles}
  font-weight: bold;

  &:last-child {
    margin-right: 0;
  }
`

export const Footer = () => {
  return (
    <FooterWrapper data-testid="FooterWrapper">
      <FooterWrapperCenter data-testid="FooterWrapperCenter">
        {footerData.map(item =>
          item.Type === FooterTypes.Version ? (
            <FooterVersion data-testid="Footer-item-version" key={item.Name}>
              {`${item.Name} ${item.Value}`}
            </FooterVersion>
          ) : (
            <FooterLink
              href={item.Value}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="Footer-item-link"
              key={item.Name}
            >
              {'\u2022'} {item.Name}
            </FooterLink>
          ),
        )}
      </FooterWrapperCenter>
    </FooterWrapper>
  )
}

export default Footer
