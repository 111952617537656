import React from 'react'

import EnumField from './PropertyFields/EnumField'
import ResourceField from './PropertyFields/ResourceField'
import VaultField from './PropertyFields/VaultField'
import NumericField from './PropertyFields/NumericField'
import StringField from './PropertyFields/StringField'
import ToggleField from './PropertyFields/ToggleField'
import EditorField from './PropertyFields/EditorField'
import ObjectField from './PropertyFields/ObjectField'
import TemplateField from './PropertyFields/TemplateField'
import { IProperty, PropertyFormatType, PropertyType } from '~/lib/types'
import { get } from 'lodash-es'

export interface IPropertyInput {
  label?: string
  property: IProperty
  value: any
  isDeclarativelyBound?: boolean
  setFieldValue: (name: string, value: any) => void // set formik value
  testId?: string
}

const PropertyInput = (props: IPropertyInput) => {
  // use Waylay type or native type, whichever fits first.
  const type = get(props.property, 'format.type', props.property.type)
  const InputField = getInputField(type)

  return <InputField {...props} />
}

const CodeField = props => <EditorField language="javascript" {...props} />

function getInputField(type: PropertyFormatType | PropertyType) {
  switch (type) {
    // waylay specific types
    case PropertyFormatType.Enum:
      return EnumField
    case PropertyFormatType.Resource:
      return ResourceField
    case PropertyFormatType.Template:
      return TemplateField
    case PropertyFormatType.Vault:
      return VaultField
    case PropertyFormatType.Code:
      return CodeField

    // native input types
    case PropertyType.Integer:
    case PropertyType.Long:
    case PropertyType.Double:
    case PropertyType.Float:
      return NumericField
    case PropertyType.Boolean:
      return ToggleField
    case PropertyType.Object:
      return ObjectField
    case PropertyType.String:
    default:
      return StringField
  }
}

export default PropertyInput
