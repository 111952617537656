import React from 'react'
import styled from '@emotion/styled'
import { Search } from '@streamlinehq/streamline-bold/lib/interface-essential'
import { Astronomy } from '@streamlinehq/streamline-bold/lib/science'
import {
  StreamlineIcon,
  colors,
  concrete,
  carbon,
} from '@waylay/react-components'

import QueryString from '~/hooks/useQueryString'

const NoResults = styled.div`
  height: 12rem;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.header`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1rem;
`

const Description = styled.p`
  margin: 0;
  margin-top: 0.2rem;
  color: ${carbon};
`

const LargeIcon = styled.span`
  font-size: 32px;
  color: ${colors.withWeight(concrete, 600)};
`

const NoResultsWithQuery = ({ className }) => (
  <NoResults className={className}>
    <div>
      <LargeIcon>
        <StreamlineIcon iconData={Search.SearchBar1} />
      </LargeIcon>
      <Title>No results matched your search.</Title>
    </div>
  </NoResults>
)

interface INoResultsProps {
  className?: string
}

interface IEmptyCollectionProps {
  className?: string
  title?: string
  description?: string
  icon?: React.ReactNode
}

export const EmptyCollection = ({
  className,
  icon,
  title,
  description,
}: IEmptyCollectionProps) => (
  <NoResults className={className} data-testid="no-results">
    <div>
      <LargeIcon>
        {icon ?? (
          <StreamlineIcon iconData={Astronomy.AstronomyTelescopeStars} />
        )}
      </LargeIcon>
      <Title>{title ?? "It's pretty empty in here"}</Title>
      {description && <Description>{description}</Description>}
    </div>
  </NoResults>
)

export default function NoResultsWrapper({ className }: INoResultsProps) {
  const { query } = QueryString.useContainer()

  return query?.query ? (
    <NoResultsWithQuery className={className} />
  ) : (
    <EmptyCollection className={className} />
  )
}
