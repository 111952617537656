import styled from '@emotion/styled'
import React from 'react'
import {
  Segment,
  clearSky,
  concrete,
  colors,
  carbon,
} from '@waylay/react-components'
import { ClickableWrapper, Title, Description } from './Common'
import { ILearnItem } from './DiscoverDataComponent'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const CardWrapper = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 50;
`

const CardContainer = styled(Segment)`
  width: 100%;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  margin: 15px;
`
const ImageWrapper = styled.div`
  height: 150px;
  overflow: visible;
`
const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`
const TextWrapper = styled.div`
  padding: 1.2em 1em 1em;
`

const StyledLearnMore = styled.div`
  text-decoration: none;
  color: ${clearSky};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: 'pointer';
  white-space: nowrap;
`

const CarouselContainer = styled.div`
  position: relative;
  display: grid;
  padding-bottom: 2em;
`
const Text = styled.div`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${colors.withWeight(carbon, 500)};
  padding: 1.25em 1.25em 0.5em 1em;
`

const Card = ({ item }: { item: ILearnItem }) => (
  <CardContainer>
    <ClickableWrapper
      data-testid={`clickableWrapper-${item.title}`}
      href={item.link}
      target="_blank"
    >
      <ImageWrapper>
        <Image src={item.image} />
      </ImageWrapper>
      <TextWrapper>
        <Title>{item.title}</Title>
        <Description>{item.description}</Description>
        <StyledLearnMore data-testid={`learnMore-${item.title}`}>
          Learn more
        </StyledLearnMore>
      </TextWrapper>
    </ClickableWrapper>
  </CardContainer>
)

const Dot = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 50%;
  cursor: pointer;
`

const CustomDot = props => {
  return (
    <Dot
      style={{ backgroundColor: props?.active ? clearSky : concrete }}
      onClick={props?.onClick}
    />
  )
}

const CAROUSEL_RESPONSIVENESS_CONFIG = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1301, min: 800 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

export const DiscoverComponent = ({
  discoverData,
}: {
  discoverData: ILearnItem[]
}) => {
  return (
    <CarouselContainer>
      <Text>Discover Waylay</Text>
      <Carousel
        responsive={CAROUSEL_RESPONSIVENESS_CONFIG}
        arrows
        draggable
        infinite
        keyBoardControl
        pauseOnHover
        renderDotsOutside
        showDots
        renderButtonGroupOutside
        customDot={<CustomDot />}
      >
        {discoverData.map((item, index) => (
          <CardWrapper key={`${item.title}-${index}`} data-testid={item.title}>
            <Card item={item} />
          </CardWrapper>
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

export default DiscoverComponent
