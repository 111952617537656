import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { AlarmStatus, TaskStatus } from '~/lib/types'
import PieChart from './PieChart'
import client from '../../lib/client'
import { useAsync } from 'react-async'
import { capitalize } from 'lodash-es'
import { StreamlineIcon, Loader, Segment } from '@waylay/react-components'
import { Alerts } from '@streamlinehq/streamline-bold/lib/interface-essential'
import { REFRESH_INTERVAL } from '~/lib/Constants'
import { useLogin } from '../App/LoginContext'

const PieChartsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  grid-gap: 1.25em;
  height: 100%;
`
enum PieChartType {
  Alarms = 'Alarms',
  Tasks = 'Tasks',
}
export interface IPieChartData {
  name: PieChartType
  series?: number[]
  total?: number
  sanitizedSeries?: number[]
  labels: string[]
  colors: string[]
  redirectPath: string
  isLoading?: boolean
  isError?: boolean
}

const defaultPieChartData = {
  isLoading: true,
}

const Wrapper = styled(Segment)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`

const alarmsTemplate: IPieChartData = {
  ...defaultPieChartData,
  colors: ['#ff0000', '#5b9ad5', '#70ac47'],
  name: PieChartType.Alarms,
  labels: Object.values(AlarmStatus).map(capitalize),
  redirectPath: `/${PieChartType.Alarms.toLowerCase()}/?query=status:`,
}

const tasksTemplate: IPieChartData = {
  ...defaultPieChartData,
  colors: ['#70ac47', '#5b9ad5', '#ff0000'],
  name: PieChartType.Tasks,
  labels: Object.values(TaskStatus).map(capitalize),
  redirectPath: `/${PieChartType.Tasks.toLowerCase()}/?query=status:`,
}

const fetchAlarmsCounterByStatus = async (alarmStatus: AlarmStatus) => {
  const data = await client.alarms.search({
    size: 0,
    status: alarmStatus,
  })
  return data.total
}

const fetchTasksCounterByStatus = async (taskStatus: TaskStatus) => {
  return await client.tasks.count({
    status: taskStatus,
  })
}

const PieChartDataComponent = () => {
  const { token } = useLogin()
  const [pieDataState, setPieDataState] = useState<IPieChartData[]>([
    { ...alarmsTemplate },
    { ...tasksTemplate },
  ])

  const fetchState = (
    fetcher: (status: String) => Promise<number>,
    statusEnum: typeof TaskStatus | typeof AlarmStatus,
    type: PieChartType,
  ) => {
    return useAsync({
      deferFn: async () => {
        return await Promise.all(Object.values(statusEnum).map(fetcher))
      },
      onResolve(data) {
        setPieDataState(previousState => {
          return previousState.map(item => {
            return item.name === type
              ? (item = {
                  ...(type === PieChartType.Alarms
                    ? alarmsTemplate
                    : tasksTemplate),
                  isLoading: false,
                  series: data,
                  total: data ? data.reduce((a, b) => a + b, 0) : 0,
                  sanitizedSeries: setMinSliceWidth(data),
                  isError: false,
                })
              : item
          })
        })
      },
      onReject() {
        setPieDataState(previousState => {
          return previousState.map(item => {
            return item.name === type
              ? (item = {
                  ...(type === PieChartType.Alarms
                    ? alarmsTemplate
                    : tasksTemplate),
                  isLoading: false,
                  series: [],
                  total: 0,
                  sanitizedSeries: [],
                  isError: true,
                })
              : item
          })
        })
      },
    })
  }

  const fetchAlarmsState = fetchState(
    fetchAlarmsCounterByStatus,
    AlarmStatus,
    PieChartType.Alarms,
  )
  const fetchTasksState = fetchState(
    fetchTasksCounterByStatus,
    TaskStatus,
    PieChartType.Tasks,
  )

  useEffect(() => {
    if (token) {
      fetchAlarmsState.run()
      fetchTasksState.run()
    }
    const intervalId = setInterval(() => {
      fetchAlarmsState.run()
      fetchTasksState.run()
    }, REFRESH_INTERVAL)
    return () => {
      clearInterval(intervalId)
    }
  }, [token])

  return (
    <>
      <PieChartsRow>
        {pieDataState.map(dataItem => (
          <div key={dataItem.name}>
            {dataItem.isLoading ? (
              <Wrapper>
                <Loader size={80} />
              </Wrapper>
            ) : dataItem?.isError ? (
              <Wrapper>
                <div>
                  <StreamlineIcon
                    iconData={Alerts.AlertTriangle}
                    width={40}
                    height={40}
                  />
                  <div>Something went wrong</div>
                </div>
              </Wrapper>
            ) : (
              <PieChart data={dataItem} key={dataItem.name} />
            )}
          </div>
        ))}
      </PieChartsRow>
    </>
  )
}

function setMinSliceWidth(
  series: number[],
  options: { minSegment: number } = { minSegment: 1 },
): number[] {
  const total = series.reduce((a, b) => a + b, 0)
  if (total <= 100) return series

  return series.map(element => {
    if (element < 0) return 0
    else {
      const percentageValue = Math.floor((100 * element) / total)
      if (percentageValue <= 0) {
        return options.minSegment
      } else if (percentageValue >= 100 - series.length) {
        return 100 - (series.length - options.minSegment)
      } else {
        return percentageValue
      }
    }
  })
}

export default PieChartDataComponent
