import React, { useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import { random, times } from 'lodash-es'
import { GeneralItem } from '../GeneralItem'
import useWindowDimensions from '~/components/Simulator/useWindowDimensions'
import { Segment } from 'semantic-ui-react'
import styled from '@emotion/styled'

interface IRectangleTopography {
  horizontalStartingPoint?: number
  verticalStartingPoint?: number
  horizontalCornerRadius?: number
  verticalCornerRadius?: number
  width?: number
  height?: number
}

interface IBarTopography extends IRectangleTopography {
  nbBars?: number
  isRandomWidth?: boolean
}

interface ILogoTopography {
  xCoordinateFromCenter?: number
  yCoordinateFromCenter?: number
  radius?: number
  strokeWidth?: number
}

/**
 * Interface for configuring the Loading component.
 */
export interface ILoadingConfig {
  /**
   * An array of RectangleTopography objects representing checkboxes to be displayed in the loading component.
   */
  checkboxOrIcon?: IRectangleTopography[]
  /**
   * An array of RectangleTopography objects representing rectangles to be displayed in the loading component.
   */
  rectangles?: IRectangleTopography[]
  /**
   * An array of RectangleTopography objects representing buttons to be displayed in the loading component.
   */
  buttons?: IRectangleTopography[]
  /**
   * An array of BarTopography objects representing bars to be displayed in the loading component.
   */
  bars?: IBarTopography[]
  /**
   * An array of LogoTopography objects representing logos to be displayed in the loading component.
   */
  logo?: ILogoTopography[]
  /**
   * The width of the loading component.
   */
  loaderWidth?: number
  /**
   * The height of the loading component.
   */
  loaderHeight?: number
  /**
   * The number of times the loading animation should repeat.
   */
  nbRepeats?: number
  /**
   * The speed of the shimmer effect in the loading component.
   */
  shimmerEffectSpeed?: number
  /**
   * The position of the loading component in an array of components.
   */
  positionInArray?: number
  /**
   * Whether the background of the loading component should be white.
   */
  isWhiteBackground?: boolean
}

/**
 * Renders a loading skeleton with customizable topography.
 *
 * @param {ILoadingConfig} loaderConfig - An object containing the configuration for the loading skeleton.
 * @returns {JSX.Element} - The loading skeleton component.
 */
export function LoadingSkeleton(loaderConfig: ILoadingConfig = {}) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  const BAR_HEIGHT = 15

  /**
   * Draws a circle with the given topography.
   *
   * @param {ILogoTopography} logo - The topography of the circle to draw.
   * @param {string} dataTestId - The data-testid attribute for the circle.
   * @returns {JSX.Element} - The circle component.
   */
  const drawCircle = (logo: ILogoTopography, dataTestId: string) => (
    <circle
      cx={logo.xCoordinateFromCenter || 27}
      cy={logo.yCoordinateFromCenter || 27}
      r={logo.radius || 27}
      strokeWidth={logo.strokeWidth || 3}
      data-testid={dataTestId}
    />
  )
  const drawCircles = (circles: ILogoTopography[] = [], dataTestId: string) => {
    return circles.map((circle, index) =>
      drawCircle(circle, `${dataTestId}-${index}`),
    )
  }

  /**
   * Draws a rectangle with the given topography.
   *
   * @param {Topography} rectangle - The topography of the rectangle to draw.
   * @param {string} dataTestId - The data-testid attribute for the rectangle.
   * @param {number} [index] - The index of the rectangle in the array.
   * @returns {JSX.Element} - The rectangle component.
   */
  const drawRectangle = (
    rectangle: IRectangleTopography,
    dataTestId: string,
    index?: number,
  ) => {
    return (
      <rect
        x={rectangle.horizontalStartingPoint || 0}
        y={rectangle.verticalStartingPoint || 0}
        rx={rectangle.horizontalCornerRadius || 4}
        ry={rectangle.verticalCornerRadius || 4}
        width={
          rectangle.width ||
          (dataTestId === 'loading-rectangle' ? windowWidth : 17)
        }
        height={
          rectangle.height ||
          (dataTestId === 'loading-rectangle' ? windowHeight : 17)
        }
        key={index}
        data-testid={`${dataTestId}-${index}`}
      />
    )
  }

  /**
   * Draws an array of rectangles with the given topography.
   *
   * @param {Topography[]} [rectangles=[]] - The topography of the rectangles to draw.
   * @param {string} dataTestId - The data-testid attribute for the rectangles.
   * @returns {JSX.Element[]} - An array of rectangle components.
   */
  const drawRectangles = (
    rectangles: IRectangleTopography[] = [],
    dataTestId: string,
  ) => {
    return rectangles.map((rectangle, index) =>
      drawRectangle(rectangle, dataTestId, index),
    )
  }

  /**
   * Calculates the width of a bar based on its topography and the window width.
   *
   * @param {boolean} [isRandomWidth=true] - Whether the bar should have a random width.
   * @param {number} [width=windowWidth] - The width of the bar.
   * @param {number} horizontalStartingPoint - The horizontal starting point of the bar.
   * @returns {number} - The calculated width of the bar.
   */
  const calculateBarWidth = (
    isRandomWidth = true,
    width = windowWidth,
    horizontalStartingPoint: number,
  ) => {
    const effectiveWidth =
      horizontalStartingPoint && horizontalStartingPoint < width
        ? width - horizontalStartingPoint
        : width
    return isRandomWidth ? random(0.2, 1) * effectiveWidth : effectiveWidth
  }

  /**
   * Draws a bar with the given topography.
   *
   * @param {IBarTopography} bar - The topography of the bar to draw.
   * @param {number} index - The index of the bar in the group.
   * @param {number} groupIndex - The index of the group of bars.
   * @param {string} dataTestId - The data-testid attribute for the bar.
   * @returns {JSX.Element} - The bar component.
   */
  const drawBar = (
    bar: IBarTopography,
    index: number,
    groupIndex: number,
    dataTestId: string,
  ) => (
    <rect
      x={bar.horizontalStartingPoint || 0}
      y={bar.verticalStartingPoint || 0 + index * BAR_HEIGHT}
      rx={bar.horizontalCornerRadius || 4}
      ry={bar.verticalCornerRadius || 4}
      width={calculateBarWidth(
        bar.isRandomWidth,
        bar.width,
        bar.horizontalStartingPoint,
      )}
      height={bar.height || 10}
      key={index}
      data-testid={`${dataTestId}-${groupIndex}-${index}`}
    />
  )

  /**
   * Draws a group of bars with the given topography.
   *
   * @param {IBarTopography} barGroup - The topography of the group of bars to draw.
   * @param {number} groupIndex - The index of the group of bars.
   * @param {string} dataTestId - The data-testid attribute for the bars.
   * @returns {JSX.Element[]} - An array of bar components.
   */
  const drawBarsGroup = (
    barGroup: IBarTopography,
    groupIndex: number,
    dataTestId: string,
  ) => {
    return times(barGroup.nbBars || 1, index =>
      drawBar(barGroup, index, groupIndex, dataTestId),
    )
  }

  /**
   * Draws an array of groups of bars with the given topography.
   *
   * @param {Topography[]} [bars=[]] - The topography of the groups of bars to draw.
   * @param {string} dataTestId - The data-testid attribute for the bars.
   * @returns {JSX.Element[]} - An array of bar components.
   */
  const drawBars = (bars: IBarTopography[] = [], dataTestId: string) => {
    return bars.map((barGroup, index) =>
      drawBarsGroup(barGroup, index, dataTestId),
    )
  }

  const drawButton = (
    button: IRectangleTopography,
    index: number,
    dataTestId: string,
  ) => (
    <rect
      x={button.horizontalStartingPoint || 0}
      y={button.verticalStartingPoint || 0}
      rx={button.horizontalCornerRadius || 4}
      ry={button.verticalCornerRadius || 4}
      width={button.width || 130}
      height={button.height || 30}
      key={index}
      data-testid={`${dataTestId}-${index}`}
    />
  )
  const drawButtons = (
    buttons: IRectangleTopography[] = [],
    dataTestId: string,
  ) => {
    return buttons.map((button, index) => drawButton(button, index, dataTestId))
  }

  const Loader = useMemo(() => {
    return ContentLoader
  }, [])
  const cleansedConfig = loaderConfig
  return (
    <Loader
      width={cleansedConfig.loaderWidth || windowWidth}
      height={cleansedConfig.loaderHeight || windowHeight}
      speed={cleansedConfig.shimmerEffectSpeed || 2}
      primaryColor="#f3f3f3"
      secondaryColor="#eaeaea"
    >
      {cleansedConfig.logo && drawCircles(cleansedConfig.logo, 'loading-logo')}
      {cleansedConfig.checkboxOrIcon &&
        drawRectangles(cleansedConfig.checkboxOrIcon, 'loading-checkboxOrIcon')}
      {cleansedConfig.rectangles &&
        drawRectangles(cleansedConfig.rectangles, 'loading-rectangle')}
      {cleansedConfig.bars && drawBars(cleansedConfig.bars, 'loading-bar')}
      {cleansedConfig.buttons &&
        drawButtons(cleansedConfig.buttons, 'loading-button')}
    </Loader>
  )
}

export function Loading(loaderConfig: ILoadingConfig = {}) {
  const DynamicBackground = loaderConfig.isWhiteBackground
    ? styled(Segment)``
    : styled(GeneralItem)``
  return (
    <>
      {times(loaderConfig?.nbRepeats || 1, index => (
        <DynamicBackground
          key={index}
          data-testid={`loading-${loaderConfig.positionInArray || 0}-${index}`}
          style={{ padding: '0.825em' }}
        >
          <LoadingSkeleton {...loaderConfig} />
        </DynamicBackground>
      ))}
    </>
  )
}

export default Loading
