import styled from '@emotion/styled'
import React from 'react'
import Welcome from './Welcome'
import PieChartDataComponent from './PieChartDataComponent'
import Cards from './Cards'
import ColumnCharts from './ColumnCharts/index'
import DiscoverDataComponent from './DiscoverDataComponent'
import Footer from './Footer'
import { LandingPageContainer } from './useLandingPage'

const LayoutContainer = styled.div`
  min-height: 100vh;
`
const ContentContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
`
const GraphsContainer = styled.div`
  display: grid;
  padding: 1.25em;
`

const GraphsRow = styled.div`
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: 280px;
  grid-gap: 1.25em;
`

const LandingPage = () => (
  <LandingPageContainer.Provider>
    <LayoutContainer>
      <ContentContainer>
        <Welcome />
        <GraphsContainer>
          <Cards />
          <GraphsRow>
            <PieChartDataComponent />
            <ColumnCharts />
          </GraphsRow>
        </GraphsContainer>
        <DiscoverDataComponent />
      </ContentContainer>
      <Footer />
    </LayoutContainer>
  </LandingPageContainer.Provider>
)

export default LandingPage
