import styled from '@emotion/styled'
import { get, random, isEmpty } from 'lodash-es'
import { colors, carbon } from '@waylay/react-components'

import ProfileContainer from '~/components/Dashboard/Sidebar/useProfile'

const GENERIC_MESSAGES = ['Welcome back', 'Hi there']

const pickMessage = (messages: string[] = GENERIC_MESSAGES, name?: string) => {
  const msg = messages[random(messages.length - 1)]

  return isEmpty(name) ? msg : `${msg}, ${name}`
}

const Welcome = () => {
  const state = ProfileContainer.useContainer()
  const firstName = get(state, 'data.firstName')

  const welcomeMessage = pickMessage(GENERIC_MESSAGES, firstName)

  return (
    <MessageContainer>
      <WelcomeMessage>{welcomeMessage}</WelcomeMessage>
    </MessageContainer>
  )
}

const MessageContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`

const WelcomeMessage = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${colors.withWeight(carbon, 500)};
  font-family: 'Fira Sans';
  padding-left: 1.25em;
`

export default Welcome
