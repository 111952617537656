/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { useAsync } from 'react-async'
import { createContainer } from 'unstated-next'

import client from '~/lib/client'
import { Plug } from '~/lib/types'
import { PlugTypeSingular } from './usePlug'

type PlugWithType = Omit<Plug, 'type'> & { type: PlugTypeSingular }

const fetchSensors = (): Promise<PlugWithType[]> =>
  client.sensors
    .list()
    .then((list = []) =>
      (list as Plug[]).map(
        plug => ({ ...plug, type: 'sensor' } as PlugWithType),
      ),
    )

const fetchActuators = (): Promise<PlugWithType[]> =>
  client.actuators
    .list()
    .then((list = []) =>
      (list as Plug[]).map(
        plug => ({ ...plug, type: 'actuator' } as PlugWithType),
      ),
    )

const fetchAllPlugs = async () =>
  await Promise.all([fetchSensors(), fetchActuators()]).then(
    ([sensors, actuators]) => [...sensors, ...actuators],
  )

function usePlugs() {
  return useAsync(fetchAllPlugs, { watch: client.token })
}

export default createContainer(usePlugs)
