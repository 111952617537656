import React, { useState } from 'react'
import styled from '@emotion/styled'
import loadable from '@loadable/component'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { colors, concrete } from '@waylay/react-components'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'

import Topbar from './Topbar/Topbar'
import ExploreTopBar from '~/components/Explore/TopBar'
import Sidebar from './Sidebar/Sidebar'
import { useLogin } from '../App/LoginContext'
import useConnectivity from './useConnectivity'
import ExplorerContainer from '../Explore/ExplorerContainer'
import PlugsContainer from '../Plugins/usePlugs'
import { withPadding } from '../Common/WithPadding'
import EditorContainer from '../Tasks/Editor/EditorContext'
import TemplateContainer from '../Tasks/Editor/TemplateContext'
import Error from './Error'
import { Flag, useFlags } from '~/lib/flags'
import { retryImport } from '~/lib/retry'
import { ChartContainer } from '../Explore/Editor/ChartContainer'
import LandingPage from '../Landing/LandingPage'

const Alarms = loadable(() => retryImport(() => import('../Alarms/Alarms')))
const Integrations = loadable(() =>
  retryImport(() => import('../Integrations/Integrations')),
)
const Settings = loadable(() =>
  retryImport(() => import('../Settings/Settings')),
)
const Resources = loadable(() =>
  retryImport(() => import('../Resources/Resources')),
)
const ResourceTypes = loadable(() =>
  retryImport(() => import('../ResourceTypes/ResourceTypes')),
)
const ResourceConstraints = loadable(() =>
  retryImport(() => import('../ResourceConstraints/ResourceConstraints')),
)
const Plugins = loadable(() => retryImport(() => import('../Plugins/Plugins')))
const Tasks = loadable(() => retryImport(() => import('../Tasks/Tasks')))
const Subflows = loadable(() =>
  retryImport(() => import('../Subflows/Details/Subflows')),
)
const Webscripts = loadable(() =>
  retryImport(() => import('../Webscripts/Webscripts')),
)
const Templates = loadable(() =>
  retryImport(() => import('../Templates/Templates')),
)
const Logs = loadable(() => retryImport(() => import('../Logs')))
const PluginDetail = loadable(() =>
  retryImport(() => import('../Plugins/Detail')),
)
const PluginNew = loadable(() => retryImport(() => import('../Plugins/New')))
const Explore = loadable(() => retryImport(() => import('../Explore/Explore')))
const EditorWrapper = loadable(() =>
  retryImport(() => import('../Tasks/Editor/EditorWrapper')),
)
const DataQueries = loadable(() =>
  retryImport(() => import('../Analytics/DataQueries')),
)
const Storage = loadable(() => retryImport(() => import('../Storage')))
const Byoml = loadable(() => retryImport(() => import('../Byoml')))
const Applications = loadable(() =>
  retryImport(() => import('../Applications/Applications')),
)
const Nlp = loadable(() => retryImport(() => import('../Nlp')))
const Simulator = loadable(() => retryImport(() => import('../Simulator')))

const LayoutContainer = styled('div')`
  display: flex;
  min-height: 100vh;
`

const ContentContainer = styled('div')`
  flex: 1;
  max-height: 100vh;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  background: ${colors.withWeight(concrete, 100)};
`

const SidebarContainer = styled('div')`
  height: 100vh;
  position: relative;

  top: 0;
  left: 0;
  bottom: 0;

  background: ${colors.withWeight(concrete, 100)};
  border-right: solid 1px ${concrete};
`

const dragHandler = e => e.preventDefault()

const Dashboard = () => {
  const { isAuthenticated } = useLogin()
  const [hasError, setHasError] = useState(false)
  const location = useLocation()
  useConnectivity()

  if (!isAuthenticated) return null

  return (
    <LayoutContainer
      onDrop={dragHandler}
      onDragEnter={dragHandler}
      onDragOver={dragHandler}
      onDragLeave={dragHandler}
    >
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <ContentContainer>
        <Topbar />
        {/* We reset the error state on navigation */}
        <ErrorBoundary
          FallbackComponent={Error}
          onReset={() => setHasError(false)}
          onError={err => Sentry.captureException(err)}
          resetKeys={[hasError, location]}
        >
          <Switch>
            <Route
              exact
              path="/explore"
              render={() => (
                <Flag
                  name={['explore']}
                  component={Explorer}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route exact path="/plugins/new" component={PluginNew} />
            <Route exact path="/plugins/:type/:name" component={PluginDetail} />
            <Route exact path="/designer">
              <PlugsContainer.Provider>
                <TemplateContainer.Provider>
                  <EditorContainer.Provider>
                    <EditorWrapper />
                  </EditorContainer.Provider>
                </TemplateContainer.Provider>
              </PlugsContainer.Provider>
            </Route>
            <Route
              path="/alarms"
              render={() => (
                <Flag
                  name={['alarms']}
                  component={withPadding(Alarms)}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route path="/integrations" children={withPadding(Integrations)} />
            <Route path="/resources" children={withPadding(Resources)} />
            <Route
              path="/resourcetypes"
              children={withPadding(ResourceTypes)}
            />
            <Route
              path="/resourceconstraints"
              children={withPadding(ResourceConstraints)}
            />
            <Route path="/plugins" children={withPadding(Plugins)} />
            <Route path="/subflows" children={withPadding(Subflows)} />
            <Route path="/tasks" children={withPadding(Tasks)} />
            <Route path="/templates">
              <PlugsContainer.Provider>
                <Templates />
              </PlugsContainer.Provider>
            </Route>
            <Route
              path="/nlp/rulebase"
              render={() => (
                <Flag
                  name={['nlp']}
                  component={Nlp}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route
              path="/logs"
              children={withPadding(Logs, { display: 'flex' })}
            />
            <Route
              path="/administration/settings"
              children={withPadding(Settings)()}
            />
            <Route
              path="/webscripts"
              render={() => (
                <Flag
                  name={['webscripts']}
                  component={Webscripts}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route
              path="/analytics/queries"
              render={() => (
                <Flag
                  name={['queries']}
                  component={withPadding(DataQueries)}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route
              path="/analytics/byoml"
              render={() => (
                <Flag
                  name={['byoml']}
                  component={withPadding(Byoml)}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route
              path="/storage"
              render={() => (
                <Flag
                  name={['storage']}
                  component={Storage}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route
              path="/applications"
              render={() => (
                <Flag
                  name={['applications']}
                  component={withPadding(Applications)}
                  fallbackRender={() => <Redirect to="/" />}
                />
              )}
            />
            <Route path="/simulator" children={withPadding(Simulator)} />
            <DefaultRoute />
          </Switch>
        </ErrorBoundary>
      </ContentContainer>
    </LayoutContainer>
  )
}

const Explorer = () => (
  <ChartContainer.Provider>
    <ExplorerContainer.Provider>
      <ExploreTopBar />
      <Explore />
    </ExplorerContainer.Provider>
  </ChartContainer.Provider>
)

const DefaultRoute = () => {
  const { whitelabeling } = useFlags()
  if (whitelabeling) {
    return <Redirect to="/alarms" />
  }

  return <LandingPage />
}

export default Dashboard
