import React from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useHistory } from 'react-router-dom'
import { formatCompactNumber } from './Common'
import { IPieChartData } from './PieChartDataComponent'
import { carbon, Segment } from '@waylay/react-components'
import styled from '@emotion/styled'

export const ChartWrapper = styled(Segment)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Details = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${carbon};
  min-width: 250px;
  min-height: 100%;
`
export const PieChart = ({ data }: { data: IPieChartData }) => {
  const history = useHistory()

  function redirectOnClick(
    labelsAddress: string[],
    labelsIndex: string | number,
  ) {
    const sort = data.name === 'Alarms' ? '&sort=lastTriggeredTime:desc' : ''
    history.push(`${data.redirectPath}${labelsAddress[labelsIndex]}${sort}`)
  }
  const opts: ApexOptions = {
    chart: {
      type: 'donut',
      events: {
        dataPointSelection: (event, chartContext, config) =>
          redirectOnClick(chartContext.w.config.labels, config.dataPointIndex),
        legendClick: (chartContext, seriesIndex) =>
          redirectOnClick(chartContext.w.config.labels, seriesIndex),
      },
      animations: {
        enabled: true,
      },
    },
    tooltip: {
      custom: function ({ seriesIndex }) {
        return `<div 
          style="position: relative;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            background: ${data.colors[seriesIndex].toString()};">
              ${data.labels[seriesIndex].toString()}:
              <b>
                &nbsp${data.series[seriesIndex].toString()} 
              </b>
        </div>`
      },
    },
    colors: data.colors,
    dataLabels: {
      enabled: false,
    },
    labels: data.labels,
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: '80%',
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '12px',
              color: '#59595B',
              offsetY: 10,
            },
            total: {
              showAlways: true,
              show: true,
              fontSize: '30px',
              color: '#59595B',
              fontWeight: 'bold',
              label: formatCompactNumber(data.total),
              formatter: function () {
                return String(data.name)
              },
            },
          },
        },
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '12px',
      height: 33,
      offsetY: 5,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  return (
    <ChartWrapper>
      <Details>
        <Chart
          options={opts}
          series={data.sanitizedSeries}
          type="donut"
          height={280}
        />
      </Details>
    </ChartWrapper>
  )
}
export default PieChart
