import React, { useState } from 'react'
import { LandingPageContainer } from '../useLandingPage'
import { css } from '@emotion/core'
import { Button, Modal, Segment, Input } from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import styled from '@emotion/styled'
import ColumnChart from './ColumnChart'
import { COLUMN_CHARTS_TITLES } from '~/lib/Constants'

const Dot = styled.div`
  height: 15px;
  width: 15px;
  background-color: #8d342d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`

const Container = ({ chartData, field, setIsModalOpen }) => {
  const { contextThresholds, setContextThreshold } =
    LandingPageContainer.useContainer()

  const [threshold, setThreshold] = useState(null)

  const [showModal, hideModal] = useModal(() => {
    return (
      <Modal
        isOpen
        onRequestClose={() => {
          setThreshold(contextThresholds[field])
          hideModal()
        }}
        onAfterClose={() => setIsModalOpen(false)}
      >
        <Segment.Group
          data-testid="settings-dialog"
          css={css`
            width: 600px;
          `}
        >
          <Segment.Header data-testid="settings-dialog-title">
            {COLUMN_CHARTS_TITLES[field]} Settings
          </Segment.Header>

          <Segment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input.Group style={{ marginRight: '10px' }}>
                <Button disabled data-testid="settings-dialog-label">
                  <Dot />
                  Threshold
                </Button>

                <Input
                  data-testid="threshold-input"
                  style={{ width: '100%', borderRadius: '0 0.33rem 0.33rem 0' }}
                  type="number"
                  value={threshold ?? contextThresholds[field]}
                  onInput={event => setThreshold(event.target.value)}
                />
              </Input.Group>
            </div>
          </Segment>

          <Modal.Actions>
            <Button
              outline
              kind="secondary"
              onClick={() => {
                setThreshold(contextThresholds[field])
                hideModal()
              }}
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              data-testid="save"
              onClick={() => {
                setContextThreshold(field, threshold)
                hideModal()
              }}
            >
              Save
            </Button>
          </Modal.Actions>
        </Segment.Group>
      </Modal>
    )
  }, [threshold, setThreshold, contextThresholds[field]])

  return (
    <ColumnChart
      showModal={showModal}
      chartData={chartData}
      field={field}
      setIsModalOpen={setIsModalOpen}
    />
  )
}

export default Container
